import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service p-6">
      <h1 className="text-4xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-base">
          Welcome to MedJeex! These Terms of Service ("Terms") govern your use of the MedJeex website and services
          (collectively, the "Service"). By accessing or using the Service, you agree to comply with and be bound by
          these Terms. If you do not agree to these Terms, please do not use the Service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Eligibility</h2>
        <p className="text-base">
          By using the Service, you represent and warrant that you are at least 13 years old and have the legal
          capacity to enter into these Terms. If you are under 18, you may use the Service only with the consent of
          your parent or guardian.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Account Registration</h2>
        <p className="text-base">
          To access certain features of the Service, you may be required to create an account. You agree to provide
          accurate, current, and complete information during the registration process and to update such information as
          necessary. You are responsible for maintaining the confidentiality of your account credentials and for all
          activities that occur under your account.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Use of the Service</h2>
        <p className="text-base">You agree to use the Service only for lawful purposes and in accordance with these Terms. You shall not:</p>
        <ul className="list-disc list-inside ml-4">
          <li>Use the Service in any way that violates any applicable local, state, national, or international law or regulation.</li>
          <li>Upload, post, or transmit any content that is illegal, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable.</li>
          <li>Impersonate any person or entity, or falsely state or misrepresent your affiliation with a person or entity.</li>
          <li>Engage in any activity that interferes with or disrupts the Service or the servers and networks connected to the Service.</li>
          <li>Use any automated means, including bots, to access the Service for any purpose without our express written permission.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Content Ownership and License</h2>
        <p className="text-base">
          MedJeex does not claim ownership of the content you upload, post, or transmit through the Service ("User Content").
          However, by submitting User Content, you grant MedJeex a worldwide, non-exclusive, royalty-free, transferable,
          sub-licensable license to use, reproduce, modify, distribute, and display such content in connection with the Service.
        </p>
        <p className="text-base">
          You acknowledge that MedJeex may, at its discretion, remove or refuse to display any User Content that violates these
          Terms or is otherwise objectionable.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Intellectual Property</h2>
        <p className="text-base">
          All content provided on or through the Service, including but not limited to text, graphics, logos, icons, images,
          audio clips, video clips, digital downloads, and software, is the property of MedJeex or its content suppliers and
          is protected by applicable intellectual property laws. You agree not to copy, reproduce, distribute, or create
          derivative works of any content provided by MedJeex without our express written consent.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. Payment and Refund Policy</h2>
        <p className="text-base">
          Some features of the Service may require payment. All fees are non-refundable unless otherwise stated. MedJeex
          reserves the right to change its pricing at any time. If a payment dispute arises, MedJeex may suspend or
          terminate your access to the Service until the dispute is resolved.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Privacy Policy</h2>
        <p className="text-base">
          Your use of the Service is also governed by our Privacy Policy, which describes how we collect, use, and protect
          your personal information. By using the Service, you consent to the collection and use of your information as
          outlined in the Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Termination</h2>
        <p className="text-base">
          MedJeex reserves the right to terminate or suspend your access to the Service, without notice, for conduct that
          MedJeex believes violates these Terms or is harmful to other users of the Service or to MedJeex.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. Disclaimer of Warranties</h2>
        <p className="text-base">
          The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.
          MedJeex does not warrant that the Service will be uninterrupted, error-free, or free of viruses or other harmful components.
          MedJeex is not responsible for any damage to your device or data loss that may result from your use of the Service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">11. Limitation of Liability</h2>
        <p className="text-base">
          To the fullest extent permitted by law, MedJeex and its affiliates, officers, directors, employees, and agents shall not be liable
          for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:
        </p>
        <ul className="list-disc list-inside ml-4">
          <li>Your access to or use of or inability to access or use the Service.</li>
          <li>Any conduct or content of any third party on the Service.</li>
          <li>Any content obtained from the Service.</li>
          <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">12. Governing Law</h2>
        <p className="text-base">
          These Terms shall be governed by and construed in accordance with the laws of India, without regard
          to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located within
          India to resolve any legal matter arising from these Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">13. Changes to the Terms</h2>
        <p className="text-base">
          MedJeex reserves the right to modify these Terms at any time. If we make changes, we will notify you by revising the
          "Effective Date" at the top of these Terms. Your continued use of the Service following the posting of any changes
          constitutes your acceptance of the new Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">14. Contact Us</h2>
        <p className="text-base">
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <p className="text-base">
          MedJeex Support Team<br />
          Email: <a href="mailto:medjeex.query@gmail.com" className="text-blue-500 hover:underline">medjeex.query@gmail.com</a>
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
