import React, { useState } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Medjeex_Logo from "../../../asset/images/Medjeex_Logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); 

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post("https://medjeex.com/api/v1/login", {
      email,
      password,
    })
      .then((response) => {
       
        if (response.data.success === true) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          navigate("/"); 
        } else {
          setError("Invalid email or password."); 
        }
      })
      .catch((err) => {
        setError("An error occurred. Please try again."); 
        console.log(err); 
      });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#f1f2ed]">
      <div className="w-full flex justify-center">
        <a href="/">
          <img
            src={Medjeex_Logo}
            alt="Medjeex Logo"
            className="w-60 h-auto max-h-40 object-contain" 
          />
        </a>
      </div>
      <form
        className="w-96 p-8 border border-gray-300 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-bold text-center mb-6">Login</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}{" "}
    
        <label htmlFor="email" className="block mb-2">
          Email:
        </label>
        <input
          type="email"
          value={email}
          autoComplete="off"
          placeholder="Email"
          className="w-full p-3 mb-4 border rounded"
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password" className="block mb-2">
          Password:
        </label>
        <input
          type="password"
          value={password}
          placeholder=""
          className="w-full p-3 mb-4 border rounded"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          className="w-full py-3 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Login
        </button>
        <Link
          to="/forgotPassword"
          className="block text-center text-blue-500 hover:underline mt-4"
        >
          Forgot Password?
        </Link>
        <p className="mt-4 text-center">
          Don't have an Account?{" "}
          <Link to="/signup" className="text-blue-500 hover:underline">
            Sign up
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;