import React from 'react';

const ShippingDeliveryPolicy = () => {
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-4">Shipping & Delivery Policy</h1>
      <p className="text-gray-600 mb-6">Last updated: August 31, 2024</p>

      <p className="mb-4">
        This Shipping & Delivery Policy is part of our{' '}
        <a href="https://www.medjeex.com/termsOfService" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
          Terms of Service ("Terms")
        </a>{' '}
        and should therefore be read alongside our main Terms. Please carefully review our Shipping & Delivery Policy when purchasing our products. This policy will apply to any order you place with us.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">What Are My Shipping & Delivery Options?</h2>

      <h3 className="text-xl font-semibold mt-6 mb-2">Standard Shipping</h3>
      <p className="mb-4">We offer standard shipping on all orders.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Do You Deliver Internationally?</h2>
      <p className="mb-4">
        We offer worldwide shipping. Standard shipping Charge is valid on international orders.
      </p>
      <p className="mb-4">
        Please note, we may be subject to various rules and restrictions in relation to some international deliveries, and you may be subject to additional taxes and duties over which we have no control. If such cases apply, you are responsible for complying with the laws applicable to the country where you live and will be responsible for any such additional costs or taxes.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Questions About Returns?</h2>
      <p className="mb-4">
        If you have questions about returns, please review our{' '}
        <a href="https://www.medjeex.com/returnRefundPolicy" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
          Return Policy
        </a>.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">How Can You Contact Us About This Policy?</h2>
      <p className="mb-4">If you have any further questions or comments, you may contact us by:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Email: <a href="mailto:medjeex.query@gmail.com" className="text-blue-600 hover:underline">medjeex.query@gmail.com</a>
        </li>
        <li>
          Online contact form: <a href="https://www.medjeex.com/" className="text-blue-600 hover:underline">https://www.medjeex.com/</a>
        </li>
      </ul>
    </div>
  );
};

export default ShippingDeliveryPolicy;
