import React from "react";
import CourseImage1 from "../../asset/Course1.jpg";
import CourseImage2 from "../../asset/Course2.jpg";
import ProductCard from "../../component/productCard/ProductCard";

const Course = () => {
  const products = [
    {
      image: CourseImage2,
      title: "SGP Program",
      rating: 5,
      realPrice: 6000,
      offerPrice: 2999,
      description: [
        "Comprehensive coverage of all topics.",
        "Focus on both practical and theoretical knowledge.",
        "Interactive sessions and doubt clearing.",
        "Experienced faculty with industry knowledge.",
      ],
    },
    {
      image: CourseImage1,
      title: "Test Series",
      rating: 5,
      realPrice: 2999,
      offerPrice: 1499,
      description: [
        "Extensive practice material for preparation.",
        "Mock tests with detailed analysis.",
        "Covers all competitive exam patterns.",
        "Flexible schedule and self-paced learning.",
      ],
    },
    // Add more products here with their respective descriptions
  ];

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-10">
        {products.map((product, index) => (
          <ProductCard
            key={index}
            image={product.image}
            title={product.title}
            rating={product.rating}
            realPrice={product.realPrice}
            offerPrice={product.offerPrice}
            description={product.description} // Pass description as a prop
          />
        ))}
      </div>
    </>
  );
};

export default Course;
