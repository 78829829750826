import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

const PaymentSuccess = () => {
  const searchQuery = useSearchParams()[0];
  const referenceNumber = searchQuery.get("reference");

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4">
      <div className="mb-6">
        <FaCheckCircle className="text-6xl animate-bounce" />
      </div>
      <h1 className="text-4xl font-bold mb-4 text-center">
        Payment Successful!
      </h1>
      <p className="text-lg mb-8 text-center">
        Thank you for your purchase. Your order has been placed successfully.
      </p>
      {referenceNumber && (
        <div className="bg-transparent rounded-lg shadow-lg p-4 text-white mb-6">
          <p className="text-lg font-semibold">Reference Number:</p>
          <p className="text-xl font-bold">{referenceNumber}</p>
        </div>
      )}
      <button 
        onClick={() => window.location.href = '/courses'} 
        className="bg-white text-green-500 font-semibold px-6 py-3 rounded-full shadow-md hover:bg-green-100 transition duration-300 ease-in-out"
      >
        Continue Shopping
      </button>
    </div>
  );
};

export default PaymentSuccess;
