import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import whyimage from "../../asset/whylightimg.webp";
import contactUsBg from "../../asset/bg-image.jpg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post("https://medjeex.com/api/v1/contactUs", formData);
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        mobileNumber: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      alert("Failed to submit the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConnectClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => navigate("/contact"), 500);
  };

  return (
    <div className="bg-gray-500 bg-opacity-50 overflow-hidden">
      <div
        className="p-10 rounded-lg shadow-lg w-full grid grid-cols-1 lg:grid-cols-2 gap-6 bg-cover"
        style={{ backgroundImage: `url(${contactUsBg})` }}
      >
        <div>
          <h2 className="text-4xl font-bold mb-4 text-white">Contact Us</h2>
          <h4 className="text-lg font-semibold mb-6 text-white">
            We will reach out to you soon! 😊
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-white text-xl font-medium"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block bg-transparent text-white w-full max-w-lg px-3 py-2 border-b-2 border-cyan-500 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Aditya Anand"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-white text-xl font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block bg-transparent text-white w-full max-w-lg px-3 py-2 border-b-2 border-cyan-500 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="aditya@gmail.com"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="mobileNumber"
                className="block text-white text-xl font-medium"
              >
                Phone
              </label>
              <input
                type="tel"
                id="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                className="mt-1 block bg-transparent text-white w-full max-w-lg px-3 py-2 border-b-2 border-cyan-500 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="9999999999"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block text-white text-xl font-medium"
              >
                Message
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 block bg-transparent text-white w-full max-w-lg px-3 py-2 border-b-2 border-cyan-500 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                rows="5"
                placeholder="Enter your message here..."
                required
              ></textarea>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-transparent text-white py-2 px-4 text-2xl border-y-2 border-cyan-500 w-full rounded-md hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={whyimage}
            alt="Why MedJeex"
            className="w-full h-auto max-h-[600px] object-cover rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-between m-10">
        <div>
          <p className="text-6xl font-extrabold text-cyan-900 mb-4">
            Let's Get In Touch
          </p>
          <p className="text-4xl font-bold text-cyan-700 pb-5">
            Start Your Journey ...
          </p>
        </div>
        <button
          onClick={handleConnectClick}
          className="ml-4 bg-transparent text-cyan-800 text-6xl py-2 px-6 rounded-lg border-2 border-cyan-800 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-600 transition-all duration-300 ease-in-out shadow-lg hover:shadow-xl"
        >
          Let's Connect
        </button>
      </div>
      <div className="bg-gray-400 text-black p-6 rounded-lg shadow-lg m-10 flex flex-col lg:flex-row items-center lg:items-start gap-6">
        <div className="lg:w-1/2">
          <h3 className="text-3xl font-bold mb-4">Our Address</h3>
          <p className="text-lg mb-2">Dlf The Ultima, Sector 81</p>
          <p className="text-lg mb-2">S-193</p>
          <p className="text-lg mb-2">Gurugram, haryana, 122004</p>
          <p className="text-lg mb-2">India</p>
        </div>
        <div className="lg:w-1/2 w-full h-80">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.0052202484785!2d76.94770517499951!3d28.388910295103063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3dbcd88dd08f%3A0xffc3efc4e2da082e!2sDLF%20The%20Ultima!5e0!3m2!1sen!2sin!4v1725191513058!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Maps Location"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
