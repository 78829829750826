import React, { useState } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Medjeex_Logo from "../../../asset/images/Medjeex_Logo.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post("https://medjeex.com/api/v1/password/forgot", {
      email,
    })
      .then((response) => {
        if (response.data.status) {
          alert("Check your email for a reset password link.");
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#f1f2ed]">
      <div className="w-full flex justify-center mb-6">
        <img
          src={Medjeex_Logo}
          alt="Medjeex Logo"
          className="w-60 h-auto max-h-40 object-contain"
        />
      </div>
      <form
        className="w-96 p-8 border border-gray-300 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-bold text-center mb-6">Forgot Password</h2>
        <label htmlFor="email" className="block mb-2">
          Email:
        </label>
        <input
          type="email"
          autoComplete="off"
          placeholder="Email"
          className="w-full p-3 mb-4 border rounded"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          type="submit"
          className="w-full py-3 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Send
        </button>
        <p className="mt-4 text-center">
          Have an Account?{" "}
          <Link to="/login" className="text-blue-500 hover:underline">
            Login
          </Link>
        </p>
      </form>
    </div>
  );
};

export default ForgotPassword;