import React, { useState, useEffect } from "react";
import {
  FaFacebookF,
  FaYoutube,
  FaArrowUp,
  FaTelegram,
  FaInstagram,
} from "react-icons/fa";
import Medjeex_Logo from "../../asset/images/Medjeex_Logo.png";

function Footer() {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <footer className="bg-gray-800 text-gray-200 py-8">
      <div className="w-11/12 sm:w-9/12 lg:w-8/12 mx-auto flex flex-col sm:flex-row justify-between items-center space-y-8 sm:space-y-0 sm:space-x-4 px-4">
        {/* Left section with logo and contact information */}
        <div className="flex flex-col items-center sm:items-start space-y-4">
          <img src={Medjeex_Logo} alt="logo" className="h-16 w-auto" />{" "}
          <p className="text-sm text-center sm:text-left">
            <a href="mailto:medjeex.query@gmail.com">medjeex.query@gmail.com</a>
          </p>
          <p className="text-xs text-center sm:text-left">
            Copyright &copy; {new Date().getFullYear()} Medjeex. All rights
            reserved.
          </p>
        </div>

        {/* Center section with links */}
        <div className="flex flex-col sm:flex-row justify-center items-start text-left space-y-4 sm:space-y-0 sm:space-x-8">
          {/* Services Column */}
          <div className="flex flex-col items-start text-left sm:items-start sm:text-left">
            <h5 className="font-semibold text-xl mb-2">SERVICES</h5>
            <ul className="space-y-2 font-light">
              <li>
                <a href="/courses" className="hover:text-blue-400">
                  Courses
                </a>
              </li>
              <li>
                <a href="/courses" className="hover:text-blue-400">
                  Test Series
                </a>
              </li>
            </ul>
          </div>

          {/* Company Column */}
          <div className="flex flex-col items-start text-left sm:items-start sm:text-left">
            <h5 className="font-semibold text-xl mb-2">COMPANY</h5>
            <ul className="space-y-2 font-light">
              <li>
                <a href="/termsOfService" className="hover:text-blue-400">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/privacyPolicy" className="hover:text-blue-400">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/returnRefundPolicy" className="hover:text-blue-400">
                  Return and Refund Policy
                </a>
              </li>
              <li>
                <a href="/shippingDeliveryPolicy" className="hover:text-blue-400">
                  Shipping and Delivery Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Right section with social media icons */}
        <div className="flex space-x-4 mt-4 sm:mt-0">
          <a
            href="https://www.facebook.com/share/eygc53d8LKZJ2Vkn/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-500 text-3xl"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://t.me/AdityaAnandPhysics"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-800 text-3xl"
          >
            <FaTelegram />
          </a>
          <a
            href="https://www.instagram.com/medjeex?igsh=MWNmeWQzb3hxNGJwZw%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-700 hover:text-blue-500 text-3xl"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.youtube.com/@adityaanandphysics"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-600 hover:text-red-500 text-3xl"
          >
            <FaYoutube />
          </a>
        </div>
      </div>

      {showButton && (
        <button
          className="fixed bottom-4 right-4 bg-gray-600 text-white p-2 rounded-full shadow-md hover:bg-gray-700 focus:outline-none"
          onClick={scrollToTop}
        >
          <FaArrowUp className="text-xl" />
        </button>
      )}
    </footer>
  );
}

export default Footer;
