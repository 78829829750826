import React from 'react'
import ContactUs from './ContactUs'
import Footer from '../../component/footer/Footer'

const Contact = () => {
  return (
    <>
        <ContactUs/>
        <Footer/>
    </>
  )
}

export default Contact