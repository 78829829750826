import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Medjeex_Logo from "../../../asset/images/Medjeex_Logo.png";

import avatar1 from "../../../asset/avatars/avatar1.png";
import avatar2 from "../../../asset/avatars/avatar2.png";
import avatar3 from "../../../asset/avatars/avatar3.png";
import avatar4 from "../../../asset/avatars/avatar4.png";
import avatar5 from "../../../asset/avatars/avatar5.png";
import avatar6 from "../../../asset/avatars/avatar6.png";
import avatar7 from "../../../asset/avatars/avatar7.png";
import avatar8 from "../../../asset/avatars/avatar8.png";
import avatar9 from "../../../asset/avatars/avatar9.png";
import avatar10 from "../../../asset/avatars/avatar10.png";
import avatar11 from "../../../asset/avatars/avatar11.png";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [countdown, setCountdown] = useState(10);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11];

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post("https://medjeex.com/api/v1/register", {
      name: username,
      email,
      password,
      imageUrl: selectedAvatar,
      phoneNumber,
    })
      .then((response) => {
        if (response.data.success) {
          setShowMessage(true);
          const countdownInterval = setInterval(() => {
            setCountdown((prev) => {
              if (prev === 1) {
                clearInterval(countdownInterval);
                navigate("/login");
              }
              return prev - 1;
            });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err.response || err);
        alert(err);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#f1f2ed]">
      <div className="w-full flex justify-center">
        <a href="/">
          <img
            src={Medjeex_Logo}
            alt="Medjeex Logo"
            className="w-60 h-auto max-h-40 object-contain"
          />
        </a>
      </div>
      {!showMessage ? (
        <form
          className="w-96 p-8 border border-gray-300 rounded-lg shadow-md"
          onSubmit={handleSubmit}
        >
          <h2 className="text-3xl font-bold text-center mb-6">Sign Up</h2>
          <label htmlFor="username" className="block mb-2">
            Username:*
          </label>
          <input
            type="text"
            value={username}
            placeholder="Username"
            className="w-full p-3 mb-4 border rounded"
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <label htmlFor="email" className="block mb-2">
            Email:*
          </label>
          <input
            type="email"
            value={email}
            autoComplete="off"
            placeholder="Email"
            className="w-full p-3 mb-4 border rounded"
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password" className="block mb-2">
            Password:*
          </label>
          <input
            type="password"
            value={password}
            placeholder="Password"
            className="w-full p-3 mb-4 border rounded"
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <label className="block mb-2">Select an Avatar:*</label>
          <div className="relative">
            <div
              className="w-full p-3 mb-4 bg-white border rounded cursor-pointer flex justify-between items-center"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {selectedAvatar ? (
                <img
                  src={selectedAvatar}
                  alt="Selected Avatar"
                  className="w-8 h-8 rounded-full inline-block mr-2"
                />
              ) : (
                "Choose an Avatar"
              )}
              <span
                className={`${
                  dropdownOpen ? "text-blue-600" : "text-gray-500"
                } text-sm`}
              >
                {dropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute z-10 mt-1 w-full p-4 bg-white border border-gray-300 rounded shadow-lg"
              >
                <div className="grid grid-cols-4 gap-4">
                  {avatars.map((avatar, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer p-1 ${
                        selectedAvatar === avatar
                          ? "border-blue-500"
                          : "border-gray-300"
                      }`}
                      onClick={() => {
                        setSelectedAvatar(avatar);
                        setDropdownOpen(false);
                      }}
                    >
                      <img
                        src={avatar}
                        alt={`Avatar ${index + 1}`}
                        className="w-16 h-16 object-cover rounded-full"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <label htmlFor="phoneNumber" className="block mb-2">
            Phone Number:*
          </label>
          <input
            type="text"
            value={phoneNumber}
            placeholder="Phone Number"
            className="w-full p-3 mb-4 border rounded"
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />

          <button
            type="submit"
            className="w-full py-3 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Sign Up
          </button>
          <p className="mt-4 text-center">
            Have an Account?{" "}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login
            </Link>
          </p>
        </form>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen text-center">
          <div className="text-9xl font-bold mb-4 text-green-600">{countdown}</div>
          <p className="text-lg text-green-800">
            Thank you for signing up! Please check your inbox to verify your
            email address. You'll be redirected to the login page shortly.
          </p>
        </div>
      )}
    </div>
  );
};

export default Signup;
