import React, { useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Medjeex_Logo from "../../../asset/images/Medjeex_Logo.png";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    Axios.post("https://medjeex.com/api/v1/password/reset/${encodeURIComponent(token)}", { password })
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage("Password has been reset successfully.");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        } else {
          setErrorMessage("Password reset failed. Please try again.");
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        if (err.response) {
          setErrorMessage(
            err.response.data.message || "An error occurred. Please try again."
          );
        } else {
          setErrorMessage("Network error. Please try again.");
        }
      });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#f1f2ed]">
      <div className="w-full flex justify-center mb-6">
        <img
          src={Medjeex_Logo}
          alt="Medjeex Logo"
          className="w-60 h-auto max-h-40 object-contain"
        />
      </div>
      <form
        className="w-96 p-8 border border-gray-300 rounded-lg shadow-md bg-white"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-bold text-center mb-6">Reset Password</h2>
        <label htmlFor="password" className="block mb-2">
          New Password:
        </label>
        <input
          type="password"
          placeholder="Enter new password"
          className="w-full p-3 mb-4 border rounded"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        {errorMessage && (
          <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="text-green-500 text-sm mb-4">{successMessage}</p>
        )}
        <button
          type="submit"
          className="w-full py-3 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Reset
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;