import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon

const WhatsAppFloat = () => {
  const handleWhatsAppClick = () => {
    window.location.href = "https://wa.me/+919818121922";
  };

  return (
    <div
      className="fixed bottom-14 right-2 md:bottom-8 md:right-8 lg:bottom-14 lg:right-5 cursor-pointer z-1000 animate-bounce"
      onClick={handleWhatsAppClick}
    >
      <FaWhatsapp className="text-green-500 text-[60px] md:text-[50px] lg:text-[60px]" />
    </div>
  );
};

export default WhatsAppFloat;