import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy p-6">
      <h1 className="text-4xl font-bold mb-6">MedJeex Privacy Policy</h1>
      {/* <p className="text-sm text-gray-500 mb-8">Effective Date: [Date]</p> */}

      <p className="text-base mb-4">
        MedJeex ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, www.medjeex.com (the "Site"), and use our services (collectively, the "Service"). By accessing or using the Service, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access the Site or use the Service.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
        
        <h3 className="text-xl font-semibold mb-2">1.1 Personal Information</h3>
        <p className="text-base mb-4">
          We may collect personal information that you voluntarily provide to us when you register on the Site, subscribe to our newsletter, fill out a form, or interact with the Service in other ways. This information may include:
        </p>
        <ul className="list-disc list-inside ml-4 mb-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Payment information</li>
          <li>Educational background and preferences</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">1.2 Automatically Collected Information</h3>
        <p className="text-base mb-4">
          When you visit the Site, we may automatically collect certain information about your device and usage of the Site, including:
        </p>
        <ul className="list-disc list-inside ml-4 mb-4">
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Operating system</li>
          <li>Referring URL</li>
          <li>Pages you visit on the Site</li>
          <li>Date and time of your visit</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">1.3 Cookies and Tracking Technologies</h3>
        <p className="text-base mb-4">
          We use cookies and similar tracking technologies to track the activity on our Site and hold certain information. Cookies are files with a small amount of data that are stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
        <p className="text-base mb-4">
          We may use the information we collect from you for various purposes, including to:
        </p>
        <ul className="list-disc list-inside ml-4 mb-4">
          <li>Provide, operate, and maintain the Service</li>
          <li>Improve, personalize, and expand the Service</li>
          <li>Process your transactions and manage your orders</li>
          <li>Communicate with you, including for customer service, updates, and promotional purposes</li>
          <li>Send you newsletters and marketing materials</li>
          <li>Understand and analyze how you use the Service</li>
          <li>Develop new products, services, and features</li>
          <li>Protect against, identify, and prevent fraud and other unlawful activities</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. How We Share Your Information</h2>
        <p className="text-base mb-4">
          We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
        </p>

        <h3 className="text-xl font-semibold mb-2">3.1 Service Providers</h3>
        <p className="text-base mb-4">
          We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. These service providers are bound by contractual obligations to keep your information confidential and use it only for the purposes for which we disclose it to them.
        </p>

        <h3 className="text-xl font-semibold mb-2">3.2 Legal Compliance</h3>
        <p className="text-base mb-4">
          We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
        </p>

        <h3 className="text-xl font-semibold mb-2">3.3 Business Transfers</h3>
        <p className="text-base mb-4">
          In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Your Choices</h2>

        <h3 className="text-xl font-semibold mb-2">4.1 Accessing and Updating Your Information</h3>
        <p className="text-base mb-4">
          You may access, correct, or delete your personal information by logging into your account or contacting us directly. We will take reasonable steps to update or correct your information in our possession that you have previously submitted via the Service.
        </p>

        <h3 className="text-xl font-semibold mb-2">4.2 Opting Out of Communications</h3>
        <p className="text-base mb-4">
          You may opt out of receiving promotional communications from us by following the unsubscribe link provided in our emails or by contacting us directly. Please note that you may still receive non-promotional emails from us, such as those related to your account or ongoing business relations.
        </p>

        <h3 className="text-xl font-semibold mb-2">4.3 Do Not Track Signals</h3>
        <p className="text-base mb-4">
          Our Site does not respond to Do Not Track ("DNT") signals. You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
        <p className="text-base mb-4">
          We use administrative, technical, and physical security measures to help protect your personal information. While we strive to protect your personal information, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Children’s Privacy</h2>
        <p className="text-base mb-4">
          Our Service is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can take steps to remove such information from our systems.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. International Data Transfers</h2>
        <p className="text-base mb-4">
          Your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. By using the Service, you consent to the transfer of your information to such locations.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
        <p className="text-base mb-4">
          We may update this Privacy Policy from time to time. We will notify you of any changes by updating the "Effective Date" at the top of this Privacy Policy. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
        <p className="text-base mb-4">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <p className="text-base">
          MedJeex Support <br />
          Email: <a href="mailto:medjeex.query@gmail.com" className="text-blue-500 hover:underline">medjeex.query@gmail.com</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
