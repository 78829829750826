import React from "react";

const Mission = () => {
  return (
    <div className="flex flex-col items-center mt-12 mb-20 p-5 w-4/5 mx-auto">
      <div className="text-center mb-8">
        <h2 className="text-5xl font-bold text-gray-700 mb-6 shadow-sm">
          MISSION MEDJEEX
        </h2>
      </div>
      <div className="flex flex-wrap justify-between space-y-10 md:space-y-0">
        <div className="flex-1 bg-gradient-to-r from-orange-200 to-orange-300 border-4 border-orange-400 rounded-lg shadow-lg p-6 h-auto mx-2">
          <h1 className="bg-orange-400 text-center font-semibold py-3 px-5 rounded-lg text-white">
            Comprehensive Curriculum & Expert Faculty
          </h1>
          <p className="mt-5 text-base leading-7 text-gray-700 overflow-wrap break-word hyphens-auto">
            Our well-structured curriculum covers all relevant topics
            thoroughly, focusing on both foundational concepts and advanced
            material. With access to experienced teachers, students receive
            content delivered effectively, along with personalized guidance and
            mentorship to help them reach their full potential.
          </p>
        </div>
        <div className="flex-1 bg-gradient-to-r from-blue-200 to-blue-300 border-4 border-blue-400 rounded-lg shadow-lg p-6 h-auto mx-2 relative lg:top-4">
          <h1 className="bg-blue-400 text-center font-semibold py-3 px-5 rounded-lg text-white">
            Interactive Learning & Regular Assessments
          </h1>
          <p className="mt-5 text-base leading-7 text-gray-700 overflow-wrap break-word hyphens-auto">
            We utilize modern educational tools such as online resources,
            interactive simulations, and practice tests to reinforce learning
            and problem-solving skills. Additionally, we provide frequent
            quizzes, tests, and assignments to evaluate progress, coupled with
            constructive feedback to help students improve and address
            weaknesses.
          </p>
        </div>
        <div className="flex-1 bg-gradient-to-r from-green-200 to-green-300 border-4 border-green-400 rounded-lg shadow-lg p-6 h-auto mx-2">
          <h1 className="bg-green-400 text-center font-semibold py-3 px-5 rounded-lg text-white">
            Strategic Exam Preparation & Supportive Environment
          </h1>
          <p className="mt-5 text-base leading-7 text-gray-700 overflow-wrap break-word hyphens-auto">
            Tailored coaching on exam strategies, time management, and practice
            under simulated exam conditions builds confidence and familiarity
            with the format. These components collectively create a supportive
            environment that enhances students' understanding, problem-solving
            abilities, and exam readiness.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;
