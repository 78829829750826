import React from "react";
import whyimage from "../../asset/whylightimg.webp";

const WhyMedJeex = () => {
  return (
    <>
      <div className="flex flex-col items-center mt-5 mb-5 p-5 w-11/12 sm:w-10/12 md:w-9/12 lg:w-8/12 xl:w-4/5 mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-700 mb-6 shadow-sm">
            WHY MEDJEEX?
          </h2>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center items-start mb-5 gap-6 sm:gap-8 md:gap-10 lg:gap-12 mx-4 sm:mx-6 md:mx-8 lg:mx-10 xl:mx-16">
        <div className="w-full lg:w-1/2">
          <img
            src={whyimage}
            alt="Why MedJeex"
            className="w-full h-auto max-h-[800px] object-cover rounded-lg shadow-lg border border-gray-300"
          />
        </div>
        <div className="w-full lg:w-1/2">
          <div className="flex flex-col space-y-6 sm:space-y-8 md:space-y-10 lg:space-y-12">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Live Class by India's Top Faculty
                </h3>
              </div>
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-l-[30px] sm:border-l-[50px] border-l-blue-300 border-t-transparent border-b-transparent"></div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4 justify-end">
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-r-[30px] sm:border-r-[50px] border-r-blue-300 border-t-transparent border-b-transparent"></div>
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Live Doubt Class by Program Director
                </h3>
              </div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Mentorship by Aditya Anand, IIT & AIIMS Graduates
                </h3>
              </div>
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-l-[30px] sm:border-l-[50px] border-l-blue-300 border-t-transparent border-b-transparent"></div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4 justify-end">
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-r-[30px] sm:border-r-[50px] border-r-blue-300 border-t-transparent border-b-transparent"></div>
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Module Includes Descriptive Lecture-Wise Theory
                </h3>
              </div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Test Series Includes 10,000 Questions Practice
                </h3>
              </div>
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-l-[30px] sm:border-l-[50px] border-l-blue-300 border-t-transparent border-b-transparent"></div>
            </div>
            <div className="flex items-center space-x-2 sm:space-x-4 justify-end">
              <div className="hidden sm:block w-0 h-0 border-t-[20px] sm:border-t-[36px] border-b-[20px] sm:border-b-[30px] border-r-[30px] sm:border-r-[50px] border-r-blue-300 border-t-transparent border-b-transparent"></div>
              <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-blue-300 flex items-center justify-center w-full max-w-xs h-[100px] sm:h-[120px]">
                <h3 className="text-gray-900 text-sm sm:text-lg font-semibold text-center">
                  Notes & Daily Practice Sheets
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyMedJeex;
